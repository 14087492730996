import Vue from "vue"
import SortedTablePlugin from "vue-sorted-table"
import Paginate from "@/components/widgets/pagination.vue";
const _ = require('lodash')

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default { 
  props: {
    width: {
      type: String,
      default: 'w-50 m-auto'
    },
    index: {
      type: Number,
      default: -1
    },
    data: {
      type: Object,
      default: {}
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Paginate
  },
  data() {
    return {
      busy: false,
      textInput: '',
      apiStatus: 'ไม่พบข้อมูล',
      perPage: 6,
      sortBy: 'name',
      totalItem: 0,
      currentPage: 1,
      currentData: {},
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      is_insulator: false,
      isPE: true,
      isPU: false,
      thickness: null,
      insulator_material: null,
      insulator_detail: "",
     }
  },
  watch: {
    show: function (newValue, oldValue) {
      if (newValue) {
        this.is_insulator = this.data.is_insulator ? true : false
        this.isPE = this.data.isPE
        this.isPU = this.data.isPU
        this.thickness = this.data.thickness
        this.insulator_material = this.data.insulator_material
        this.insulator_detail = this.data.insulator_detail
      }
    }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  methods: {
    onSelectPE() {
      this.thickness = null;
      this.insulator_material = null;
      this.insulator_detail = "";

      if (this.isPE) {
        this.isPE = true;
      } else {
        this.isPE = true;
        this.isPU = false;
      }
    },
    /** เปิด dialog ค้นหาข้อมูล PE*/
    onSelectPU() {
      this.thickness = null;
      this.insulator_material = null;
      this.insulator_detail = "";

      if (this.isPU) {
        this.isPU = true;
      } else {
        this.isPU = true;
        this.isPE = false;
      }
    },
    onSelected (item) {
      this.data['is_insulator'] = this.is_insulator
      this.data['isPE'] = this.isPE
      this.data['isPU'] = this.isPU
      this.data['thickness'] = this.thickness
      this.data['insulator_material'] = this.insulator_material
      this.data['insulator_detail'] = this.insulator_detail

      this.resetOption()
      
      this.$emit('onSettingCloseHandler', {
        status: true,
        data: this.data,
        index : this.index
      })


    },
    onClose () {
      this.$emit('onSettingCloseHandler', {
        status: false
      })
    },
    resetOption(){
      this.is_insulator = false
      this.isPE = true
      this.isPU = false
      this.thickness = null
      this.insulator_material = null
      this.insulator_detail = ""
    },
  }
}
